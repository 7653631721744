@font-face {
  font-family: "roboto";
  src: local("roboto"),
    url(./assets/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "lobster";
  src: local("lobster"),
    url(./assets/Lobster_Two/LobsterTwo-Regular.ttf) format("truetype");
}

.container {
  flex: 1;
  background-color: #f5cce8;
  align-items: center;
  justify-content: center;
  
}

.banner {
  padding: 10;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 25%;
  width: 100%;
  background-color: #f5cce8;
  background-size: 17px 17px;
  background-image: repeating-linear-gradient(
    45deg,
    #fdf2f4 0,
    #fdf2f4 1.7000000000000002px,
    #f5cce8 0,
    #f5cce8 50%
  );
}

.contentContainer {
  height: 65%;
  width: 100%;
  padding: 20;
  padding-bottom: 10;
  flex-direction: row;
}

.contentCard {
  flex-direction: row;
  background-color: #fff;
  flex: 1;
  border-radius: 15;
  overflow: hidden;
}

.link {
  height: 100;
  width: 100;
  margin-right: 20;
  margin-left: 20;
}

.contentText {
  width: 60%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  padding: 30;
}

.bodyText {
  text-align: center;
  font-family: "roboto";
  font-size: 16;
  font-weight: 600;
  color: #5a5353;
}

.headerText {
  text-align: center;
  font-family: "lobster";
  font-size: 44;
  color: #ed2d48;
}

.caro-image {
  width: 100%;
  max-height: 454.5px;
  margin: auto;
  object-fit: scale-down;
}

.ivory {
  background-color: #fbfaef;
}

.pad-col {
  padding: 10px;
}

body {
  background-color: #fbfaef;
}

.pink {
  background-color: #f5cce8;
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-1 {
    width: 6%;
  }
}

.logo {
  width: 60px;
}

.copy {
  margin-top: 0px;
  margin-bottom: 1px;
  font-size: 8px;
}

.pad-top {
  padding-top: 15px;
}

.marg-r {
  margin-right: 10px;
}

.pad-text {
  padding-left: 15px;
  padding-right: 15px;
}

.email {
  color: #5a5353;
}

.padding-bottom {
    padding-bottom: 20px;
}